import { Link } from "@material-ui/core";
import { navigate } from "gatsby";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import Maps from "../components/Maps";

export default function MerchantUserPage() {
  const path = typeof window !== "undefined" ? window.location.href : "";
  const [BussnesType, setBussnesType] = useState("Dining");
  const [BussnesTag, setBussnesTag] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [Yes, setYes] = useState({});
  const [Input, setInput] = useState({
    BusinessName: "",
  });

  function GetValueByID(str) {
    var a = document.getElementById(str);
    if (a) return a.value;
    else {
      return "";
    }
  }

  /*  useEffect(() => {
    window.TypeForMarchent = BussnesType?.type;
  }, [BussnesType]); */

  let obj = {};
  useEffect(() => {
    document.getElementById("default").click();
    document.getElementById("No").click();
    obj = {
      BusinessName: GetValueByID("BusinessName"),
    };
  }, []);
  console.log(obj.BusinessName);
  function Addlocation() {
    navigate("/add-location", {
      state: {
        type: BussnesType?.type,
        tagy: BussnesTag?.tagy,
      },
    });
  }
  function handleClick() {
    navigate("/merchant-registration", {
      state: {
        type: BussnesType?.type,
        tagy: BussnesTag?.tagy,
        name: document?.getElementById("Name")?.innerText,
        address: document?.getElementById("Address")?.innerText,
        city: window.MapInfo.city, //document?.querySelector(".locality")?.innerText,
        country: window.MapInfo.country, //document?.querySelector(".country-name")?.innerText,
        zipcode: window.MapInfo.zipcode, //document?.querySelector(".postal-code")?.innerText,
        region: document?.querySelector(".region")?.innerText,
        tags: document?.getElementById("Tags")?.innerText,
        rate: document?.getElementById("Rate")?.innerText,
        price: document?.getElementById("Price")?.innerText,
        reviews: document?.getElementById("Reviews")?.innerText,
        url: document?.getElementById("URL")?.innerText,
        phone: document?.getElementById("PHONE")?.innerText,
        reviews: document?.getElementById("Reviews")?.innerText,
        dayOpenMonday: document?.getElementById("DayOpenMonday")?.innerText,
        timeOpenMonday: document?.getElementById("TimeOpenMonday")?.innerText,
        timeCloseMonday: document?.getElementById("TimeCloseMonday")?.innerText,
        dayOpenTuesday: document?.getElementById("DayOpenTuesday")?.innerText,
        timeOpenTuesday: document?.getElementById("TimeOpenTuesday")?.innerText,
        timeCloseTuesday:
          document?.getElementById("TimeCloseTuesday")?.innerText,
        dayOpenWednesday:
          document?.getElementById("DayOpenWednesday")?.innerText,
        timeOpenWednesday:
          document?.getElementById("TimeOpenWednesday")?.innerText,
        timeCloseWednesday:
          document?.getElementById("TimeCloseWednesday")?.innerText,
        dayOpenThursday: document?.getElementById("DayOpenThursday")?.innerText,
        timeOpenThursday:
          document?.getElementById("TimeOpenThursday")?.innerText,
        timeCloseThursday:
          document?.getElementById("TimeCloseThursday")?.innerText,
        dayOpenFriday: document?.getElementById("DayOpenFriday")?.innerText,
        timeOpenFriday: document?.getElementById("TimeOpenFriday")?.innerText,
        timeCloseFriday: document?.getElementById("TimeCloseFriday")?.innerText,
        dayOpenSaturday: document?.getElementById("DayOpenSaturday")?.innerText,
        timeOpenSaturday:
          document?.getElementById("TimeOpenSaturday")?.innerText,
        timeCloseSaturday:
          document?.getElementById("TimeCloseSaturday")?.innerText,
        dayOpenSunday: document?.getElementById("DayOpenSunday")?.innerText,
        timeOpenSunday: document?.getElementById("TimeOpenSunday")?.innerText,
        timeCloseSunday: document?.getElementById("TimeCloseSunday")?.innerText,
      },
    });
  }
  return (
    <Layout>
      <main>
        <Container>
          <section className="mb-5">
            <div className="col-xl-5 col-lg-12 col-md-12 pt-3 mx-auto text-center">
              <h1 className="fs-48 fw-800 mb-5 d-none d-sm-block">
                Merchant Registration
              </h1>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-12 pb-5 mx-auto text-center">
              <Row>
                <div className="col-lg-4 col-md-12">
                  <h5 className="fw-normal fs-36">Merchant Registration</h5>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="col-xl-6 col-lg-12 col-md-12 mx-auto">
                    <div className="row merchant-btn px-xl-0 px-lg-0 px-md-0 mx-auto px-2">
                      <p className="text-start ps-0">
                        What type of business are you?
                      </p>

                      <Button
                        variant="primary"
                        id="default"
                        className={BussnesType?.type === "Dining" && "active"}
                        onClick={() => {
                          setBussnesType((x) => {
                            return { ...x, type: "Dining" };
                          });
                          setBussnesTag((y) => {
                            return {
                              ...y,
                              tagy: [
                                "African",
                                "Arabic",
                                "American",
                                "Asian",
                                "AYCE",
                                "Bakery",
                                "BBQ",
                                "Beer",
                                "Brazilian",
                                "Breakfast",
                                "Brunch",
                                "British",
                                "Bubble Tea",
                                "Buffet",
                                "Burgers",
                                "Cafe",
                                "Cakes",
                                "Caribbean",
                                "Chinese",
                                "Chocolate",
                                "Coffee",
                                "Creole",
                                "Deli",
                                "Desserts",
                                "Drinks",
                                "Eastern European",
                                "Egyptian",
                                "Fine Dining",
                                "French",
                                "German",
                                "Gluten-Free",
                                "Grills",
                                "Greek",
                                "Halal",
                                "Healthy",
                                "International",
                                "Italian",
                                "Indian",
                                "Japanese",
                                "Karaoke",
                                "Korean",
                                "Kosher",
                                "Live Entertainment",
                                "Mediterranean",
                                "Mexican",
                                "Middle Eastern",
                                "Organic",
                                "Pakistani",
                                "Pasta",
                                "Pastry",
                                "Persian",
                                "Pizza",
                                "Portuguese",
                                "Poutine",
                                "Pub Food",
                                "Salad",
                                "Sandwiches",
                                "Seafood",
                                "Shawarma",
                                "Smoothies",
                                "Snacks",
                                "Spanish",
                                "Snacks",
                                "South American",
                                "Soups",
                                "Sushi",
                                "Tea",
                                "Thai",
                                "Turkish",
                                "Vegan",
                                "Vegetarian",
                                "Vietnamese",
                                "Steak",
                              ],
                            };
                          });
                        }}
                      >
                        <img
                          src="../img/gray - restaurants.svg"
                          alt="fork"
                          width="42"
                          height="42"
                        />
                        <img
                          src="../img/white - restaurants.svg"
                          alt="fork"
                          className="hide"
                          height="42"
                          width="42"
                        />
                        Dining
                      </Button>
                      <Button
                        variant="primary"
                        className={BussnesType?.type === "Retail" && "active"}
                        onClick={() => {
                          setBussnesType((x) => {
                            return { ...x, type: "Retail" };
                          });
                          setBussnesTag((y) => {
                            return {
                              ...y,
                              tagy: [
                                "Accessories",
                                "Audio and Video",
                                "Beauty & Health",
                                "Clothing",
                                "Electronics",
                                "Eyewear",
                                "Footwear",
                                "Furniture",
                                "Fragrances",
                                "Health and Nutrition",
                                "Home Furnishings",
                                "Kitchenwares",
                                "Home Appliances",
                                "Personal Care",
                                "Pet Stores",
                                "Services",
                                "Sports",
                                "Stationery",
                                "Toys and Games",
                                "Watches",
                              ],
                            };
                          });
                        }}
                      >
                        <img
                          src="../img/gray - retail.svg"
                          alt="Retail"
                          height="42"
                          width="42"
                        />
                        <img
                          src="../img/white - retail.svg"
                          alt="Retail"
                          className="hide"
                          height="42"
                          width="42"
                        />
                        Retail
                      </Button>
                      <Button
                        variant="primary"
                        className={BussnesType?.type === "Services" && "active"}
                        onClick={() => {
                          setBussnesType((x) => {
                            return { ...x, type: "Services" };
                          });
                          setBussnesTag((y) => {
                            return {
                              ...y,
                              tagy: [
                                "Automotive",
                                "AC Maintenance",
                                "Beauty",
                                "Stylist",
                                "Carpentry",
                                "Chiropractic",
                                "Counseling",
                                "Cleaning",
                                "Dry Cleaning",
                                "Healing",
                                "Laundry",
                                "Makeup artistry",
                                "Medical",
                                "Pest Control",
                                "Physical Therapy",
                                "Rehabilitation",
                                "Tailoring",
                                "Upholstery",
                              ],
                            };
                          });
                        }}
                      >
                        <img
                          src="../img/gray - services.svg"
                          alt="Service"
                          height="42"
                          width="42"
                        />
                        <img
                          src="../img/white - services.svg"
                          alt="Service"
                          className="hide"
                          height="42"
                          width="42"
                        />
                        Services
                      </Button>
                      <Button
                        variant="primary"
                        className={
                          BussnesType?.type === "Spas & Salons" && "active"
                        }
                        onClick={() => {
                          setBussnesType((x) => {
                            return { ...x, type: "Spas & Salons" };
                          });
                          setBussnesTag((y) => {
                            return {
                              ...y,
                              tagy: [
                                "Barbershop",
                                "Facial",
                                "Hair Salon",
                                "Hammam",
                                "Nail Salon",
                                "Medi-Spa",
                                "Massage",
                                "Makeup",
                                "Moroccan Bath",
                                "Wellness",
                                "Whitening",
                              ],
                            };
                          });
                        }}
                      >
                        <img
                          src="../img/gray - spas & salons.svg"
                          alt="Spas & Salons"
                          height="42"
                          width="42"
                        />
                        <img
                          src="../img/white - spas & salons.svg"
                          alt="Spas & Salons"
                          className="hide"
                          height="42"
                          width="42"
                        />
                        Spas & Salons
                      </Button>
                      <Button
                        variant="primary"
                        className={
                          BussnesType?.type === "Entertainment" && "active"
                        }
                        onClick={() => {
                          setBussnesType((x) => {
                            return { ...x, type: "Entertainment" };
                          });
                          setBussnesTag((y) => {
                            return {
                              ...y,
                              tagy: [
                                "Adventure",
                                "Amusement",
                                "Art Gallery",
                                "Comedy",
                                "Landmarks",
                                "Live Entertainment",
                                "Museums",
                                "Music",
                                "Performing Arts",
                                "Sports",
                                "Theatre",
                                "Tourism",
                              ],
                            };
                          });
                        }}
                      >
                        <img
                          src="../img/gray - entertainment.svg"
                          alt="Entertainment"
                          height="42"
                          width="42"
                        />
                        <img
                          src="../img/white - entertainment.svg"
                          alt="Entertainment"
                          className="hide"
                          height="42"
                          width="42"
                        />
                        Entertainment
                      </Button>
                      <Button
                        variant="primary"
                        className={
                          BussnesType?.type === "E-Commerce" && "active"
                        }
                        onClick={() => {
                          setBussnesType((x) => {
                            return { ...x, type: "E-Commerce" };
                          });
                        }}
                      >
                        <img
                          src="../img/gray - ecommerce.svg"
                          alt="E-Commerce"
                          height="42"
                          width="42"
                        />
                        <img
                          src="../img/white - ecommerce.svg"
                          alt="E-Commerce"
                          className="hide"
                          height="42"
                          width="42"
                        />
                        E-Commerce
                      </Button>
                      <Button
                        variant="primary"
                        className={BussnesType?.type === "Fitness" && "active"}
                        onClick={() => {
                          setBussnesType((x) => {
                            return { ...x, type: "Fitness" };
                          });
                          setBussnesTag((y) => {
                            return {
                              ...y,
                              tagy: [
                                "Group Training",
                                "Gym",
                                "Personal Training",
                                "Private Gym",
                                "Pilates",
                                "Sports Training",
                                "Studio",
                                "Swimming",
                                "Weight Loss",
                                "Yoga",
                              ],
                            };
                          });
                        }}
                      >
                        <img
                          src="../img/gray - fitness.svg"
                          alt="Fitness"
                          height="42"
                          width="42"
                        />
                        <img
                          src="../img/white - fitness.svg"
                          alt="Fitness"
                          className="hide"
                          height="42"
                          width="42"
                        />
                        Fitness
                      </Button>
                      <Button
                        variant="primary"
                        className={BussnesType?.type === "Hotels" && "active"}
                        onClick={() => {
                          setBussnesType((x) => {
                            return { ...x, type: "Hotels" };
                          });
                          setBussnesTag((y) => {
                            return {
                              ...y,
                              tagy: [
                                "Casino",
                                "Hotel",
                                "Motel",
                                "Resort",
                                "Retreat",
                                "Staycation",
                              ],
                            };
                          });
                        }}
                      >
                        <img
                          src="../img/gray - hotel.svg"
                          alt="Hotels"
                          height="42"
                          width="42"
                        />
                        <img
                          src="../img/white - hotel.svg"
                          alt="Hotels"
                          className="hide"
                          height="42"
                          width="42"
                        />
                        Hotels
                      </Button>
                      <p className="text-start ps-0">
                        Do you operate in more than one location?
                      </p>
                      <Button
                        variant="primary"
                        className={`btn-operate ${
                          Yes?.operate === "Yes" && "active"
                        }`}
                        onClick={() => {
                          setYes((x) => {
                            return { ...x, operate: "Yes" };
                          });
                          setShowResults(false);
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="primary"
                        id="No"
                        className={`btn-operate ${
                          Yes?.operate === "No" && "active"
                        }`}
                        onClick={() => {
                          setYes((x) => {
                            return { ...x, operate: "No" };
                          });
                          setShowResults(true);
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
            {showResults ? (
              <div className="col-xl-10 col-lg-10 col-md-12 pb-xl-5 mb-xl-5 mx-auto text-center">
                <Row>
                  <div className="col-lg-4 col-md-12">
                    <h5 className="fw-normal fs-36 pe-2">
                      Business Information
                    </h5>
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="col-xl-6 col-lg-12 col-md-12 mx-auto">
                      <p className="text-start ps-0">
                        What is your Business name?
                      </p>
                      <div
                        className="maps-merchant"
                        style={{ height: "442px;" }}
                      >
                        <Maps />
                      </div>
                      <div className="text-start mt-3 btn-google">
                        <p className="fs-13 text-muted mb-0">
                          By creating an account, you agree to BOGO’s
                          <a
                            href="/terms"
                            className="fs-13 text-primarylocal textDecoration"
                            style={{ paddingLeft: "0.2rem" }}
                          >
                            Terms & Conditions{" "}
                          </a>
                          and
                          <a
                            href="/terms#privacy"
                            className="fs-13 text-primarylocal textDecoration"
                            style={{ paddingLeft: "0.2rem" }}
                          >
                            Privacy Policy{" "}
                          </a>
                        </p>
                        <Link
                          onClick={handleClick}
                          className="fs-13 linkclick"
                          style={{ cursor: "pointer" }}
                        >
                          Can’t find what you’re looking for?{" "}
                        </Link>
                      </div>
                      <button
                        className="btn btn-primary mt-3 text-white border-0 btn-google"
                        onClick={handleClick}
                      >
                        Merchant Sign Up{" "}
                      </button>
                    </div>
                  </div>
                </Row>
              </div>
            ) : (
              <div className="col-xl-10 col-lg-10 col-md-12 pb-xl-5 mb-xl-5 mx-auto text-center">
                <div className="col-lg-8 col-md-12 ms-auto">
                  <div className="col-xl-6 col-lg-12 col-md-12 mx-auto">
                    <button
                      className="btn btn-primary mt-3 text-white border-0 btn-google"
                      onClick={Addlocation}
                    >
                      Merchant Sign Up{" "}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </section>
        </Container>
        <Container fluid={true} className="business-h bg-light">
          <section>
            <Container className="me-0">
              <Row>
                <div className="col-xl-7 col-lg-5 col-md-12 py-5">
                  <h2 className="fx-bold mb-4">
                    Not ready to sign up just yet?
                  </h2>
                  <h4 className="fs-bold primary-color fs-22">
                    Request a Brochure{" "}
                    <span className="bogo-par fw-normal">
                      or ask us anything!
                    </span>
                  </h4>
                  <div className="Link-become pt-5">
                    <Link to="/contactus" className="fs-18 link-color pb-2">
                      Contact Us{" "}
                      <img
                        src="../img/arrow-right.svg"
                        alt="arrow right"
                        className="ms-3"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-7 col-md-12 pe-0 ms-auto vector wb-right d-none d-sm-block">
                  <div className="row text-right">
                    <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                      <img
                        src="../img/Iphone-1.svg"
                        alt="Iphone"
                        height="578"
                        className="nav-link pe-0"
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                      <img
                        src="../img/Iphone-2.svg"
                        alt="Iphone"
                        height="578"
                        className="nav-link pe-0"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-7 col-md-12 pe-0 ms-auto vector wb-right d-block d-sm-none">
                  <div className="d-flex text-right justify-content-end">
                    <div className="col-xl-6 col-lg-6 col-md-5 pe-0">
                      <img
                        src="../img/Iphone-1.svg"
                        alt="Iphone"
                        className="nav-link pe-0 become-bogo"
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-5 pe-0">
                      <img
                        src="../img/Iphone-2.svg"
                        alt="Iphone"
                        className="nav-link pe-0 become-bogo"
                      />
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
